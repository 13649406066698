import ButtonLink from '@genai/components/ButtonLink';
import Env from '@mpx-sdk/shared/configs/env';
import { Box, Stack, Typography } from '@mui/material';

export default function Custom404() {
	return (
		<Box sx={{ bgcolor: 'white', height: '100%' }}>
			<Stack alignItems='center' height='70%' justifyContent='center' sx={{ color: 'black' }}>
				<Typography
					fontWeight='bold'
					sx={{
						backgroundImage: 'linear-gradient(29deg, #FF9B53 15.23%, #FF268E 89.7%)',
						WebkitBackgroundClip: 'text',
						color: 'transparent',
					}}
					variant='h1'
				>
					404
				</Typography>
				<Typography variant='h3'>This page does not exist</Typography>
				<br />
				<Typography variant='h6'>Here are some helpful links</Typography>
				<br />
				<Stack>
					{[
						{
							href: `${Env.MPX_WEBSITE}/library`,
							text: 'Masterpiece X Library',
						},
						{
							href: `/genai`,
							text: 'Masterpiece GenAI',
						},
					].map((link) => (
						<ButtonLink href={link.href} sx={{ color: '#FF9B53 !important' }}>
							{link.text}
						</ButtonLink>
					))}
				</Stack>
			</Stack>
		</Box>
	);
}
